<mat-dialog-content>
  <div>
    <mat-form-field class="form-field" color="accent">
      <mat-label>Id</mat-label>
      <input [(ngModel)]="id" matInput>
    </mat-form-field>
    <mat-form-field class="form-field" color="accent">
      <mat-label>Name</mat-label>
      <input [(ngModel)]="name" matInput>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="form-field" color="accent">
      <mat-label>Content Type</mat-label>
      <input [(ngModel)]="contenttype" matInput [placeholder]="'text/plain'">
    </mat-form-field>

    <mat-form-field class="form-field" color="accent">
      <mat-label>Version</mat-label>
      <input [(ngModel)]="version" matInput [placeholder]="'1.0'">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="form-field" color="accent" id="form-field-baseurl">
      <mat-label>Base url</mat-label>
      <input [(ngModel)]="baseUrl" matInput>
    </mat-form-field>
  </div>
</mat-dialog-content>


<mat-dialog-actions>
    <button (click)="onSave()" color="accent" mat-stroked-button>
        <mat-icon>add_circle_outline</mat-icon> Create
    </button>

    <button color="accent" mat-dialog-close mat-stroked-button>
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>
